import React from 'react';
import Modal from './modal';
import '../styles/footer.scss';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";


const Footer = (props) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = React.useState(false);
    React.useEffect(() => {
        if(openModal){
            document.getElementsByTagName("body")[0].style = 'overflow: hidden';
        } else {
            document.getElementsByTagName("body")[0].style = 'overflow-y: scroll';
        }
        
    }, [openModal]);
    return (
        <footer className={`${props.isHome ? 'fixed' : 'auto'}`}>
        <ul className="container-mentions">
            <li>Aurelie Hautbois © {new Date().getFullYear()}</li>
            <li>
                <button className="btn-mentions" title={intl.formatMessage({ id: "footer.mention_title" })} onClick={() => setOpenModal(true)}>
                    {intl.formatMessage({ id: "footer.mention" })}
                </button>
                {openModal && 
                    <Modal showFunc={setOpenModal}>
                        <div className="title-modal">
                            <button className="cross" onClick={() => setOpenModal(false)} aria-label="show menu"></button>
                        </div>
                        <div className="body-modal">
                            <FormattedMessage
                                id="mentions_content"
                                defaultMessage="mentions_content"
                                values={{
                                  h1: (...chunks) => <h1>{chunks}</h1>,
                                  h2: (...chunks) => <h2>{chunks}</h2>,
                                  h3: (...chunks) => <h3>{chunks}</h3>,
                                  ul: (...chunks) => <ul>{chunks}</ul>,
                                  li: (...chunks) => <li>{chunks}</li>,
                                  p: (...chunks) => <p>{chunks}</p>,
                                  linkTerms: <a href="https://www.termsandconditionsgenerator.com" target="_blank" rel="noreferrer">Terms And Conditions Generator</a> ,
                                  linkPrivacy: <a href="https://www.generateprivacypolicy.com" target="_blank" rel="noreferrer">Privacy Policy Generator</a> ,
                                  linkHome: <a href="/">aureliehautbois.com</a> ,
                                  linkMail: <a href="mailto:aurelie.hautbois@gmail.com?subject=Contact a partir des mentions légales via le site aureliehautbois.com">aurelie.hautbois@gmail.com</a> ,
                                  linkGenerateur: <a href="https://www.generer-mentions-legales.com/generateur-mentions-legales.html" target="_blank" rel="noreferrer">générateur de mentions legales</a> ,
                                  linkCnil: <a href="http://www.cnil.fr" target="_blank" rel="noreferrer">www.cnil.fr</a> ,
                                  linebreak: <br />
                                }}
                              />
                        </div>
                    </Modal>
                }
                
            </li>
        </ul>
        <ul className="container-icons">
            <li>
                <a href={'https://github.com/ahautbois'} title={intl.formatMessage({ id: "footer.github_profil" })} className="icon icon-github" target="_blank" aria-label={intl.formatMessage({ id: "footer.github_profil" })} rel="noreferrer">Github</a>
            </li>
            <li>
                <a href={'https://www.linkedin.com/in/aureliehautbois/'} title={intl.formatMessage({ id: "footer.linkedin_profil" })} className="icon icon-linkedin" target="_blank" aria-label={intl.formatMessage({ id: "footer.linkedin_profil" })} rel="noreferrer">linkedin</a>
            </li>
        </ul>
        
        </footer>
    )
}

export default Footer;

