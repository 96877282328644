import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";

const languageName = {
en: "En",
fr: "Fr",
}

const Language = () => {
return (
    <ul className="language">
        <IntlContextConsumer>
            {({ languages, language: currentLocale, defaultLanguage}) =>
            languages.map(language => (
                <li key={language}>
                    <button
                    onClick={() => changeLocale(language)}
                    style={{
                        color: currentLocale === language ? `#FAFFE8` : `rgba(250, 255, 232, 0.5)`,
                    }}
                    >
                        {languageName[language]}
                    </button>
                </li>
            ))
            }
        </IntlContextConsumer>
    </ul>
)
}

export default Language