import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
import 'sanitize.css';
import '../styles/base.scss';

const Layout = ({children, location, containerRef}) => {

    return(
        <>
        <Header isHome={(location.pathname === '/en/') || (location.pathname === '/fr/')} containerRef={containerRef}/>
        <main>{children}</main>
        <Footer isHome={(location.pathname === '/en/') || (location.pathname === '/fr/')}/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout;