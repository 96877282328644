import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modal.scss';

const Modal = (props) => {
    const containerModal = document.createElement('div');
    containerModal.classList.add('container-modal');
    const body = document.querySelector('body');
    const clickOutside = e => {
        if(containerModal.contains(e.target)){
            props.showFunc(true);
        } else {
            props.showFunc(false);
        }

    }
    React.useEffect(() => {
        body.appendChild(containerModal);
        document.addEventListener('mousedown', clickOutside);
        return () => {
            body.removeChild(containerModal);
            document.removeEventListener('mousedown', clickOutside);
        };
    }, [containerModal, body]);
    return ReactDOM.createPortal(
        props.children,
        containerModal
    );
};

export default Modal;