import React from 'react';
import { useIntl, Link } from "gatsby-plugin-intl";
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import '../styles/navigation.scss';

gsap.registerPlugin(ScrollToPlugin);

const Navigation = ({show, device, containerRef, showFunc}) =>  {

    const [shouldRender, setRender] = React.useState(show);
    const intl = useIntl();
    const [XLDevice, setXLDevice] = React.useState(false);
    React.useEffect(() => {
      if (show){
        setRender(true);
        if(window.matchMedia('(min-width: 990px)').matches){
          setXLDevice(true);
        } else {
          setXLDevice(false);
        }
      } 
      
    }, [show]);

    const onAnimationEnd = () => {
      if (!show) setRender(false);
    };
    

    return (
      shouldRender && (
      <nav className="menu" 
        style={{animation: `${device === 'mobile' ? `${show ? "fadeIn" : "fadeOut"} 0.75s ease` : 'none' }`}}
        onAnimationEnd={onAnimationEnd}
      >
        <ul>
          <li  style={{ animation: `${device === 'desktop' ? `${show ? "slideIn" : "slideOut"} 0.5s ease` : 'none' }`}}
            onAnimationEnd={onAnimationEnd}
          >
            <div className="item">
              <Link 
                  to="/#skills" 
                  title={intl.formatMessage({ id: "skills_page" })} 
                  className="link icon-skills"
                  onClick={() => {
                    if(XLDevice){
                      gsap.to(window, {
                          duration: 0.5,
                          scrollTo: Math.round(containerRef.offsetHeight / 4)
                      });
                    } 
                    showFunc(false)
                  }}
                  >
                  {intl.formatMessage({ id: "skills_page" })}
              </Link>
              <Link 
                  to="/#skills" 
                  title={intl.formatMessage({ id: "link_menu" })}
                  className="btn"
                  onClick={() => {
                    if(XLDevice){
                      gsap.to(window, {
                          duration: 0.5,
                          scrollTo: Math.round(containerRef.offsetHeight / 4)
                      });
                    }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "link_menu" })}
              </Link>
            </div>
          </li>
          <li style={{ animation: `${device === 'desktop' ? `${show ? "slideIn" : "slideOut"} 0.75s ease` : 'none' }`}}
            onAnimationEnd={onAnimationEnd}>
            <div className="item">
              <Link 
                  to="/#project" 
                  title={intl.formatMessage({ id: "project_page" })}
                  className="link icon-project"
                  onClick={() => {
                      if(XLDevice){
                        gsap.to(window, {
                            duration: 0.5,
                            scrollTo: Math.round(containerRef.offsetHeight / 2)
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "project_page" })}
              </Link>
              <Link 
                  to="/#project" 
                  title={intl.formatMessage({ id: "link_menu" })}
                  className="btn"
                  onClick={() => {
                      if(XLDevice){
                        gsap.to(window, {
                            duration: 0.5,
                            scrollTo: Math.round(containerRef.offsetHeight / 2)
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "link_menu" })}
              </Link>
            </div>
          </li>
          <li style={{ animation: `${device === 'desktop' ? `${show ? "slideIn" : "slideOut"} 0.95s ease` : 'none' }`}}
            onAnimationEnd={onAnimationEnd}>
            <div className="item">

              <Link 
                  to="/#about" 
                  title={intl.formatMessage({ id: "about_page" })}
                  className="link icon-about"
                  onClick={() => {
                      if(XLDevice){
                         gsap.to(window, {
                            duration: 0.5,
                            scrollTo: Math.round(containerRef.offsetHeight / 1.34)
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "about_page" })}
              </Link>
              <Link 
                  to="/#about" 
                  title={intl.formatMessage({ id: "link_menu" })}
                  className="btn"
                  onClick={() => {
                      if(XLDevice){
                        gsap.to(window, {
                            duration: 0.5,
                            scrollTo: Math.round(containerRef.offsetHeight / 1.34)
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "link_menu" })}
              </Link>
            </div>
          </li>
          <li style={{ animation: `${device === 'desktop' ? `${show ? "slideIn" : "slideOut"} 1.15s ease` : 'none' }`}}
            onAnimationEnd={onAnimationEnd}>
            <div className="item">
              <Link 
                  to="/#contact" 
                  title={intl.formatMessage({ id: "contact_page" })}
                  className="link icon-contact"
                  onClick={() => {
                      if(XLDevice){
                        gsap.to(window, {
                            duration: 0.5,
                            scrollTo: containerRef.offsetHeight
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "contact_page" })}
              </Link>
              <Link 
                  to="/#contact" 
                  title={intl.formatMessage({ id: "link_menu" })}
                  className="btn"
                  onClick={() => {
                      if(XLDevice){
                        gsap.to(window, {
                            duration: 0.5,
                            scrollTo: containerRef.offsetHeight
                        });
                      }
                    showFunc(false)
                  }}
                  >
                {intl.formatMessage({ id: "link_menu" })}
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      )
    )
}

export default Navigation;