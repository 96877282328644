import React from 'react';
import logo from '../images/h-letter.svg';
import { useIntl, Link } from "gatsby-plugin-intl";
import Language from './language';
import Navigation from './navigation';
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import '../styles/header.scss';

gsap.registerPlugin(ScrollToPlugin);

const Header = (props) => {
    const [isClicked, setIsClicked] = React.useState(false);
    const [device, setDevice] = React.useState('');
    const intl = useIntl();
    const toggleMenu = () =>{
        setIsClicked(!isClicked);
        if (window.matchMedia('(min-width: 800px)').matches){
            setDevice('desktop');
        } else {
            setDevice('mobile');
        }
    };
    React.useEffect(() => {
        if(isClicked){
            document.getElementsByTagName("body")[0].style = 'overflow: hidden';
        } else {
            document.getElementsByTagName("body")[0].style = 'overflow-y: scroll';
        }
        
    }, [isClicked]);
    return(
        <header className={`${props.isHome ? 'fixed' : 'auto'}`}>
            <Link 
                to="/#home" 
                title={intl.formatMessage({ id: "header.logo_title" })} 
                className="logo"
                onClick={() => {
                    gsap.to(window, {
                        duration: 1,
                        scrollTo: 0
                    });
                }}
                >
                <img src={logo} alt={intl.formatMessage({ id: "header.logo_alt" })}/>
            </Link>
            <div className="side">
                <Language />
                <button className={`menu-icon ${isClicked ? 'active' : ''}`} onClick={toggleMenu} type="button">
                    <span className="bar-1"></span>
                    <span className="bar-2"></span>
                    <span className="bar-3"></span>
                </button>
            </div>
            <Navigation  show={isClicked} showFunc={setIsClicked} device={device} containerRef={props.containerRef && props.containerRef.current}/>
        </header>
    )
};

export default Header;